<template>
  <div class="ElementsFiscaux" >
    <validationCartouche
      :hasCedantValidated="elementsFiscauxActiviteCedee.has_cedant_validated"
      :hasRepreneurValidated="elementsFiscauxActiviteCedee.has_repreneur_validated"
    />
    <div class="container-fluid">
      <h2 class="gavc-h2">Éléments fiscaux de l’activité cédée </h2>

      <div class="mt-4 mb-4 d-flex justify-content-between">
        <button
          class="gavc-btn gavc-btn--bone"
          @click="$emit('previous-step')"
        >
          <i class="gavc-icon gavc-icon-arrow-left"></i> Étape précédente
        </button>
        <button
          v-if="elementsFiscauxActiviteCedee.has_repreneur_validated && elementsFiscauxActiviteCedee.has_cedant_validated"
          class="gavc-btn"
          @click="$emit('next-step')"
        >
          <i class="gavc-icon gavc-icon-arrow-right"></i> Étape validée - Accéder à l'étape suivante
        </button>
      </div>


      <div v-if="userGroupContains(['REPRENEUR'])">
        <div class="gavc-field">
          <label class="gavc-label" for="elementsFiscauxActiviteCedee-repreneur_comment-input">
            <strong class="gavc-text-tory">En tant que repreneur</strong>, vous pouvez laisser un commentaire au cédant via le champ ci-dessous
          </label>
          <textarea
            class="gavc-textarea"
            id="elementsFiscauxActiviteCedee-repreneur_comment-input"
            maxlength="1000"
            rows="5"
            @change="modifyElementsFiscaux"
            placeholder="Je laisse mon commentaire au cédant"
            type="text"
            v-model="elementsFiscauxActiviteCedee.repreneur_comment">
          </textarea>
        </div>
      </div>

      <div class="gavc-bg-bizarre p-4" style="border-radius: 40px;" v-else-if="elementsFiscauxActiviteCedee.repreneur_comment">
        <strong> Le repreneur vous a laissé un commentaire </strong> <br>
        <span style="white-space: pre-line"> {{elementsFiscauxActiviteCedee.repreneur_comment}} </span>
      </div>

      <auditExplanation
        v-if="userGroupContains(['CEDANT'])"
        :isCedant="true"
        :isRepreneur="false"
      />

      <p class="gavc-chapo mt-4" v-else>
        <strong class="gavc-text-tory">En tant que repreneur</strong>,
        <u>vous n'avez aucun document ni information à compléter</u>, il vous est juste nécessaire de valider les documents chargés par le cédant
        puis de valider cette phase.
      </p>


      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.tva-collapse>
        Déclarations de TVA
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>
      <b-collapse id="tva-collapse" role="tabpanel">
        <p class="gavc-p">
          Documents à charger : <strong>Déclarations de TVA des années N-3, N-2 et N-1</strong>
          <span
            v-b-tooltip.hover title="Pour consulter une déclaration de TVA, rendez-vous sur impôts.gouv.fr"
          >
            <v-icon
              class="ml-2 gavc-text-tory"
              name="info-circle"
            />
          </span>
        </p>

        <h4 class="gavc-h4 mt-2"> Déclarations de TVA N-3 </h4>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFiscauxActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFiscauxActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'DECLARATION_TVA_N3', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', false)"
          :items="filterDocuments('DECLARATION_TVA_N3', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'DECLARATION_TVA_N3', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          type="DECLARATION_TVA_N3"
        />

        <h4 class="gavc-h4 mt-2"> Déclarations de TVA N-2 </h4>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFiscauxActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFiscauxActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'DECLARATION_TVA_N2', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', false)"
          :items="filterDocuments('DECLARATION_TVA_N2', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'DECLARATION_TVA_N2', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          type="DECLARATION_TVA_N2"
        />

        <h4 class="gavc-h4 mt-2"> Déclarations de TVA N-1 </h4>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFiscauxActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFiscauxActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'DECLARATION_TVA_N1', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', false)"
          :items="filterDocuments('DECLARATION_TVA_N1', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'DECLARATION_TVA_N1', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          type="DECLARATION_TVA_N1"
        />
      </b-collapse>


      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.imports-collapse>
        Déclarations d'impôts sur les sociétés
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>
      <b-collapse id="imports-collapse" role="tabpanel">
        <p class="gavc-p">
          <strong> Si la société est soumise à l'impôt sur les sociétés </strong><br>
          Documents à charger : <strong>Déclarations d'impôts sur les sociétés des années N-3, N-2 et N-1</strong>
          <span
            v-b-tooltip.hover title="Pour consulter une déclaration d'impôt, rendez-vous sur impôts.gouv.fr"
          >
            <v-icon
              class="ml-2 gavc-text-tory"
              name="info-circle"
            />
          </span>
        </p>

        <h4 class="gavc-h4 mt-2"> Déclarations d'impôts sur les sociétés N-3</h4>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFiscauxActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFiscauxActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'DECLARATION_IS_N3', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', false)"
          :items="filterDocuments('DECLARATION_IS_N3', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'DECLARATION_IS_N3', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          type="DECLARATION_IS_N3"
        />

        <h4 class="gavc-h4 mt-2"> Déclarations d'impôts sur les sociétés N-2</h4>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFiscauxActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFiscauxActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'DECLARATION_IS_N2', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', false)"
          :items="filterDocuments('DECLARATION_IS_N2', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'DECLARATION_IS_N2', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          type="DECLARATION_IS_N2"
        />

        <h4 class="gavc-h4 mt-2"> Déclarations d'impôts sur les sociétés N-1</h4>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFiscauxActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFiscauxActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'DECLARATION_IS_N1', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', false)"
          :items="filterDocuments('DECLARATION_IS_N1', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'DECLARATION_IS_N1', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          type="DECLARATION_IS_N1"
        />
      </b-collapse>

      <b-button class="mt-3 subsection-button" block href="#" v-b-toggle.contribution-economique-collapse>
        Contribution économique territoriale (CVAE et CFE)
        <span class="when-opened float-right">
          <v-icon name="chevron-down" />
        </span>
        <span class="when-closed float-right">
          <v-icon name="chevron-right" />
        </span>
      </b-button>
      <b-collapse id="contribution-economique-collapse" role="tabpanel">
        <p class="gavc-p">
          Documents à charger : <strong>Contribution économique territoriale (CVAE et CFE) des années N-3, N-2 et N-1</strong>
        </p>

        <h4 class="gavc-h4 mt-2"> Contribution économique territoriale (CVAE et CFE) N-3</h4>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFiscauxActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFiscauxActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'DECLARATION_CVAE_N3', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', false)"
          :items="filterDocuments('DECLARATION_CVAE_N3', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'DECLARATION_CVAE_N3', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          type="DECLARATION_CVAE_N3"
        />

        <h4 class="gavc-h4 mt-2"> Contribution économique territoriale (CVAE et CFE) N-2</h4>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFiscauxActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFiscauxActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'DECLARATION_CVAE_N2', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', false)"
          :items="filterDocuments('DECLARATION_CVAE_N2', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'DECLARATION_CVAE_N2', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          type="DECLARATION_CVAE_N2"
        />

        <h4 class="gavc-h4 mt-2"> Contribution économique territoriale (CVAE et CFE) N-1</h4>
        <auditItem
          :disabled="!cedantCanModified"
          @document-deleted="deleteAuditItem($event, 'elementsFiscauxActiviteCedee')"
          @document-download="downloadAuditDocument($event, 'elementsFiscauxActiviteCedee')"
          @document-uploaded="createAuditDocument($event, 'DECLARATION_CVAE_N1', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          @item-validated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', true)"
          @item-unvalidated="validateAuditItem($event, 'elementsFiscauxActiviteCedee', false)"
          :items="filterDocuments('DECLARATION_CVAE_N1', 'CEDANT')"
          @justificatif-added="createAuditItemWithoutDocument($event, 'DECLARATION_CVAE_N1', elementsFiscauxActiviteCedee.id, 'elementsFiscauxActiviteCedee')"
          type="DECLARATION_CVAE_N1"
        />
      </b-collapse>


      <!-- ######## CEDANT BUTTONS ########## -->
      <div v-if="!elementsFiscauxActiviteCedee.has_cedant_validated && userGroupContains(['CEDANT'])" class="row mt-4">
        <div class="col-12 text-right">
          <validationSectionButton
            @modify="modifyElementsFiscaux"
            @validate="validateElementsFiscaux(true)"
          />
        </div>
      </div>
      <div v-else-if="elementsFiscauxActiviteCedee.has_cedant_validated && userGroupContains(['CEDANT'])" class="row mt-4">
        <div class="col-12 text-right">
          <button
            class="gavc-btn gavc-btn--bone"
            @click="validateElementsFiscaux(false)"
          >
              Invalider cette étape
          </button>
        </div>
      </div>
    </div>


    <div v-if="!elementsFiscauxActiviteCedee.has_repreneur_validated && userGroupContains(['REPRENEUR'])" class="row mt-4">
      <div class="col-12 text-right">
        <validationSectionButton
          @validate="validateElementsFiscaux(true)"
          :withModifyButton="false"
        />
      </div>
    </div>
    <div v-else-if="elementsFiscauxActiviteCedee.has_repreneur_validated && userGroupContains(['REPRENEUR'])" class="row mt-4">
      <div class="col-12 text-right">
        <button
          class="gavc-btn gavc-btn--bone"
          @click="validateElementsFiscaux(false)"
        >
            Invalider cette étape
        </button>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12 col-md-6 pl-4 pr-4">
        <hasValidatedMessage
          v-if="elementsFiscauxActiviteCedee.has_cedant_validated"
          text="Le cédant a validé toute la section"
        />
      </div>
      <div class="col-12 col-md-6 pl-4 pr-4">
        <hasValidatedMessage
          v-if="elementsFiscauxActiviteCedee.has_repreneur_validated"
          text="Le repreneur a validé toute la section"
        />
      </div>
    </div>

    <div class="mt-4 text-center" v-if="elementsFiscauxActiviteCedee.has_repreneur_validated && elementsFiscauxActiviteCedee.has_cedant_validated">
      <button
        class="gavc-btn"
        @click="$emit('next-step')">
            Étape validée - Accéder à l'étape suivante
      </button>
    </div>
  </div>
</template>

<script>
import { APIConnected } from '@/api/APIConnected'
import auditItemsMixins from '@/mixins/auditItemsMixins'
import auditSectionInformationMixins from '@/mixins/auditSectionInformationMixins'
import userMixins from '@/mixins/userMixins'
import uxMixins from '@/mixins/uxMixins'

const apiConnectedService = new APIConnected()
const auditItem = () => import('@/components/audit/auditItem')
const auditExplanation = () => import('@/components/audit/auditExplanation')
const hasValidatedMessage = () => import('@/components/audit/hasValidatedMessage')
const validationCartouche = () => import('@/components/layout/validationCartouche')
const validationSectionButton = () => import('@/components/audit/validationSectionButton')

export default {
  name: 'ElementsFiscaux',
  props: {
    id: {
      type: Number,
    }
  },
  components: {
    auditExplanation,
    auditItem,
    hasValidatedMessage,
    validationCartouche,
    validationSectionButton
  },
  mixins: [
    auditItemsMixins,
    auditSectionInformationMixins,
    uxMixins,
    userMixins
  ],
  data: function () {
    return {
      isGettingElementsFiscaux: false,
      elementsFiscauxActiviteCedee: {
        audit: {
          has_cedant_validated: false,
          has_repreneur_validated: false,
          candidature: {
            description: '',
            offre_cession: {
              entreprise_cedee: {
                cedant: {
                  phone_number: '',
                  user: {
                    first_name: '',
                    last_name: '',
                    username: ''
                  }
                },
                name: '',
                siren: ''
              },
              price: '',
              title: '',

            },
            repreneur: {
              phone_number: '',
              user: {
                first_name: '',
                last_name: '',
                username: ''
              }
            },
            state: '3VALIDATED'
          },
        },
        cedant_comment: "",
        repreneur_comment: "",
        has_cedant_validated: false,
        has_repreneur_validated: false,
        items: [],
      },

    }
  },
  computed: {
    cedantCanModified () {
      return this.userGroupContains(['CEDANT']) && !this.elementsFiscauxActiviteCedee.has_cedant_validated
    },

    token () {
      return this.$store.state.auth.token
    },
  },
  mounted: function() {
    this.scrollToTop()
    this.getElementsFiscaux()
  },

  methods: {
    filterDocuments (type, uploader) {
      return this.elementsFiscauxActiviteCedee.items.filter(item => item.type === type && item.uploader === uploader)
    },
    documentModified () {
      this.getElementsFiscaux()
    },
    getElementsFiscaux () {
      this.isGettingElementsFiscaux = true
      this.$store.commit('loadFromAPI')
      apiConnectedService.getAuditSection(this.token, 'elementsFiscauxActiviteCedee', this.id)
      .then((result) => {
        this.elementsFiscauxActiviteCedee = result.data
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('ElementsFiscaux getElementsFiscaux API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
        this.isGettingElementsFiscaux = false
      })
    },

    modifyElementsFiscaux () {
      this.$store.commit('loadFromAPI')
      apiConnectedService.modifyAuditSection(this.token, 'elementsFiscauxActiviteCedee', this.id, this.elementsFiscauxActiviteCedee)
      .then((result) => {
        this.elementsFiscauxActiviteCedee = result.data
        this.$bvToast.hide('notification-toast')
        this.$bvToast.toast("Vos informations transmises dans l'audit ont bien été mises à jour", {
          id: 'notification-toast',
          title: 'Informations mises à jour',
          variant: 'success',
          solid: true
        })
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('ElementsFiscaux modifyElementsFiscaux API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })
    },

    validateElementsFiscaux (validationStatus) {
      const data = {}
      this.$store.commit('loadFromAPI')
      if (this.userGroupContains(['CEDANT'])) {
        data['has_cedant_validated'] = validationStatus
      }
      else if (this.userGroupContains(['REPRENEUR'])) {
        data['has_repreneur_validated'] = validationStatus
      }
      apiConnectedService.modifyAuditSection(this.token, "elementsFiscauxActiviteCedee",  this.id, data)
      .then((result) => {
        this.elementsFiscauxActiviteCedee = result.data
        if (validationStatus) {
          this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Félicitations, vous avez bien validé cette partie", {
            id: 'notification-toast',
            title: 'Section validée',
            variant: 'success',
            solid: true
          })
        }
        else {
            this.$bvToast.hide('notification-toast')
          this.$bvToast.toast("Vous avez bien invalidé cette partie, vous pouvez effectuer les modifications nécessaires, échanger avec l'autre partie puis à nouveau valider cette étape", {
            id: 'notification-toast',
            title: 'Etape invalidée',
            variant: 'danger',
            solid: true
          })
        }
        this.scrollToTop()
      })
      .catch((error) => {
        this.$store.commit('openGlobalErrorModal')
        throw new Error('ElementsFiscaux validateElementsFiscaux API Error : ' + String(error))
      })
      .finally(() => {
        this.$store.commit('endLoadingFromAPI')
      })

    },

  }
}
</script>
